import * as angular from "angular";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { autorun } from "mobx";
import { $timeout, $filter } from "ngimport";
import {
  UIRouter,
  StateService,
  Transition,
  TargetState
} from "@uirouter/core";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { UserService, SamskipNotify, FinanceService } from "Services";
import { MAIN_STATE } from "./index";
import RouteStore from "../shared/stores/RouteStore";
import { DateUtilities, UtilityFunctions } from "Utilities";
import { $uiRouter } from "Bootstrap/angular";
import GeneralLoader from "Utilities/GeneralLoader";

declare var $: any;

function financeController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();
  const previousState = !RouteStore.previousState
    ? MAIN_STATE
    : RouteStore.previousState;

  const currentUser = UserService.getUserProfile();

  // Filters Selected
  $scope.initFilter = function initFilter() {
    const Filter: any = {};

    Filter.duePeriodSelection = {
      value: [],
      name: "Tímabil eindaga",
      translate: "LABEL_FINALDUEPERIOD"
    };
    Filter.status = { value: "ALL", name: "Staða", translate: "LABEL_STATUS" };
    Filter.gjaldmidill = {
      value: null,
      name: "Gjaldmiðill",
      translate: "LABEL_CURRENCY"
    }; // for chosen selectors value must be null.
    Filter.companySelection = {
      value: [],
      name: "Fyrirtæki",
      translate: "LABEL_COMPANY"
    };

    return Filter;
  };

  loadDateSettings();

  $scope.Filter = $scope.initFilter();
  $scope.FilterBase = $scope.initFilter();
  $scope.resultText = currentStateParams.resultText;
  $scope.showCompanyFilter = false;

  $scope.finances = [];
  $scope.companyCodes = [];
  $scope.sapID = UserService.getSelectedCompany().SapID;

  // Search query
  if (currentStateParams.query) {
    $scope.query = currentStateParams.query;
  }

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  dataLoader(currentState, currentStateParams);
  $scope.Filter.companySelection.value = getCompaniesSelectedObj();

  UtilityFunctions.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));

  const orderBy = $filter("orderBy");

  $scope.search = function search(query?: string) {
    if (previousState === undefined) return;
    if (
      currentState === "finance_search" &&
      (query == null || query.length <= 0)
    ) {
      $state.go("finance_search", { query: "" });
    } else {
      $state.go("finance_search", { query });
    }
  };

  // Toggle status selection in
  // Status 1 = 0-30
  // Status 2 = 30-60
  // Status 3 = 60-90
  // Status 4 = 90+
  $scope.toggleDuePeriod = function toggleDuePeriod(status: number) {
    if (currentState === "finance_due") {
      $state.go("finance");
    }

    $timeout(() => {
      const idx = $scope.Filter.duePeriodSelection.value.indexOf(status);

      // is currently selected
      if (idx > -1) {
        $scope.Filter.duePeriodSelection.value.splice(idx, 1);
      } else {
        // is newly selected
        $scope.Filter.duePeriodSelection.value.push(status);
      }
    });
  };

  // Toggle company selection
  // Code 1000 is Samskip hf.
  // Code 1300 is Samskip Innanlands
  $scope.toggleCompany = function toggleCompany(code: string) {
    const idx = $scope.Filter.companySelection.value.indexOf(code);

    // is currently selected
    if (idx > -1) {
      $scope.Filter.companySelection.value.splice(idx, 1);
    } else {
      // is newly selected
      $scope.Filter.companySelection.value.push(code);
    }
    UserService.setWebSetting(
      USERWEBSETTINGS.SelectedCompanies,
      JSON.stringify($scope.Filter.companySelection.value)
    );
  };

  $scope.filters = function filters(item: any) {
    let addResult = true;

    if ($scope.Filter.companySelection.value.length > 0) {
      let statusResult = true;

      if (
        item.companyCodeField === 1000 &&
        $scope.Filter.companySelection.value.indexOf("1000") > -1
      ) {
        statusResult = true;
      } else if (
        item.companyCodeField === 1300 &&
        $scope.Filter.companySelection.value.indexOf("1300") > -1
      ) {
        statusResult = true;
      } else {
        statusResult = false;
      }
      addResult = statusResult;
    }

    if ($scope.Filter.duePeriodSelection.value.length > 0) {
      let statusResult = true;

      if (
        item.isOverdue30Field &&
        $scope.Filter.duePeriodSelection.value.indexOf(1) > -1
      ) {
        statusResult = true;
      } else if (
        item.isOverdue60Field &&
        $scope.Filter.duePeriodSelection.value.indexOf(2) > -1
      ) {
        statusResult = true;
      } else if (
        item.isOverdue90Field &&
        $scope.Filter.duePeriodSelection.value.indexOf(3) > -1
      ) {
        statusResult = true;
      } else if (
        item.isOverdue90PlusField &&
        $scope.Filter.duePeriodSelection.value.indexOf(4) > -1
      ) {
        statusResult = true;
      } else {
        statusResult = false;
      }
      addResult = statusResult;
    }

    if ($scope.Filter.status.value !== "ALL") {
      if (
        $scope.Filter.status.value === "deposit" &&
        item.depositField === "0"
      ) {
        addResult = false;
      } else if (
        $scope.Filter.status.value === "unpaid" &&
        item.balancedField === 1
      ) {
        addResult = false;
      } else if ($scope.Filter.status.value === "due" && !item.isOverdueField) {
        // if item is not due don't display
        addResult = false;
      }
    }

    if (
      $scope.Filter.gjaldmidill.value !== null &&
      $scope.Filter.gjaldmidill.value !== undefined &&
      $scope.Filter.gjaldmidill.value !== ""
    ) {
      if (
        !(
          item.exchangeCodeField
            .toUpperCase()
            .indexOf($scope.Filter.gjaldmidill.value.toUpperCase()) !== -1
        )
      ) {
        addResult = false;
      }
    }

    if (angular.equals($scope.Filter, $scope.FilterBase)) {
      $(".js-ContentHeader-clearFilters").hide();
    } else {
      $(".js-ContentHeader-clearFilters").show();
    }

    if (item.checked && !addResult) {
      delete item.checked;

      // Remove this invoice number from the multiActionSelected array
      $scope.multiActionSelected = $scope.multiActionSelected.filter(
        (it: string) => {
          return it !== item.invoiceNumberField;
        }
      );

      // Remove this company code from the multiActionCompanyCode array
      $scope.multiActionCompanyCode = $scope.multiActionCompanyCode.filter(
        (it: number) => {
          return it !== item.companyCodeField;
        }
      );

      $scope.numberOfSelected = $scope.multiActionSelected.length;
      if ($scope.numberOfSelected < 2) {
        $(".selectedBar").fadeTo(600, 0, () => {
          $(".selectedBar").css("display", "none");
        });
      }
    }

    return addResult;
  };

  // pagination
  $scope.currentPage = 1;
  $scope.maxSize = 10;

  /*
   * Items Per Page:
   * Everything regarding how many items to display per page
   */

  // Default value
  $scope.itemsPerPage = 20;

  // Get the settings for how many items per table page to display
  const settingsItemsPerTablePage = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.ItemsPerTablePage
  );
  if (settingsItemsPerTablePage) {
    $scope.itemsPerPage = settingsItemsPerTablePage;
  }

  // When items per page has changed, this event is triggered
  // which lets us save settings and fade list in and out
  $scope.$on("itemsPerPageChanged", (event: any, value: any) => {
    // Save the selection on the server
    UserService.setWebSetting(USERWEBSETTINGS.ItemsPerTablePage, value);

    // Display loading animation for list
    (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 0);
    (angular.element(".loaderFinanceList") as any)
      .fadeTo(300, 1)
      .delay(600)
      .fadeTo(300, 0, () => {
        (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 1);
      });
  });

  UtilityFunctions.largeSpinner("largeSpinner");

  // posts a request to server and returns a promise which is used in the watch lower on the page
  function getFinanceList() {
    if ($scope.inListAPICall) return;
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();

    FinanceService.getCompanyCodes(selectedCompany.SapID).then(codes => {
      $scope.companyCodes = codes;
      if (codes.length > 1) {
        $scope.showCompanyFilter = true;
      }
    });

    if (!currentUser) return;

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderFinanceList").fadeTo(400, 1);

    GeneralLoader.increase();
    if (selectedCompany.SapID) {
      $scope.inListAPICall = true;
      FinanceService.allTransactions(
        selectedCompany.SapID,
        $scope.dateFrom,
        $scope.dateTo
      )
        .then(handleData)
        .then(() => {
          $scope.inListAPICall = false;
        })
        .catch(() => $(".loaderFinanceList").hide(200))
        .finally(() => {
          GeneralLoader.decrease();
        });
    } else {
      SamskipNotify.displayError("TEXT_NOSAPID");
    }

    // Reset multiaction bar
    $scope.multiActionSelected = [];
    $scope.multiActionCompanyCode = [];
    $scope.numberOfSelected = 0;

    $(".selectedBar").fadeTo(600, 0, () => {
      $(".selectedBar").css("display", "none");
    });
  }

  function handleData(data: FinanceSAPTransactionWithOverdue[]) {
    $scope.finances = data;

    // new field to order by amount
    angular.forEach($scope.finances, (value: any) => {
      value.amount =
        value.amountField != null
          ? Number(value.amountField.replace(",", "."))
          : 0;
      value.accountNo = Number(value.invoiceNumberField);
      value.balancedField = Number(value.balancedField);
      value.companyCodeField = Number(value.companyCodeField);
    });

    $scope.order();

    // some animation
    $(".loaderFinanceList")
      .clearQueue()
      .fadeTo(400, 0, () => {
        $(".js-DisplayList").fadeTo(500, 1, () => {
          $(".js-ListPage-box").trigger("heightChange");
        });
      });

    UtilityFunctions.setListSizes(
      UserService.getLocalWebSettingValue(USERWEBSETTINGS.SelectedListViewSize)
    );
  }

  $scope.multiActionSelected = [];
  $scope.multiActionCompanyCode = [];
  $scope.numberOfSelected = $scope.multiActionSelected.length;

  UtilityFunctions.catToggle();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );

  // ************ Ordering and Filter functions ******************/

  $scope.selectedPredicate = {
    pred: "invoiceDateField",
    name: "Dagsetning",
    translate: "LABEL_DATE"
  };
  $scope.reverse = true;

  // For sorting bookings
  $scope.order = function order() {
    $scope.finances = orderBy(
      $scope.finances,
      $scope.selectedPredicate.pred,
      $scope.reverse
    );
  };

  // Filters array
  $scope.OrderTypes = [
    { pred: "balancedField", name: "Staða", translate: "LABEL_STATUS" },
    { pred: "accountNo", name: "Reikningsnúmer", translate: "LABEL_INVOICENO" },
    { pred: "sapTxtField", name: "Lýsing", translate: "LABEL_DESCRIPTION" },
    { pred: "bookNoField", name: "Tilvísun", translate: "LABEL_REFERENCE" },
    { pred: "invoiceDateField", name: "Dagsetning", translate: "LABEL_DATE" },
    { pred: "finalDueDateField", name: "Eindagi", translate: "LABEL_DUEDATE" },
    { pred: "amount", name: "Upphæð", translate: "LABEL_AMOUNT" },
    {
      pred: "exchangeCodeField",
      name: "Gjaldmiðill",
      translate: "LABEL_CURRENCYSHORT"
    }
  ];

  $scope.setPredicate = function setPredicate(pred: any) {
    if ($scope.selectedPredicate.pred === pred.pred) {
      $scope.reverse = !$scope.reverse;
    } else {
      $scope.selectedPredicate = pred;
    }

    $scope.order();
  };

  $scope.removeFilter = function removeFilter(filter: any) {
    angular.forEach($scope.Filter, (value, key) => {
      if (filter.name === value.name) {
        angular.forEach($scope.initFilter(), initValue => {
          if (initValue.name === value.name) {
            $scope.Filter[key] = initValue;
            UserService.setWebSetting(
              USERWEBSETTINGS.SelectedCompanies,
              JSON.stringify(($scope.Filter.companySelection.value = []))
            );
          }
        });
      }
    });
  };

  $scope.resetAllFilters = function resetAllFilters() {
    $scope.Filter = $scope.initFilter();
  };

  $scope.GetInvoice = function GetInvoice(
    partnerCode: string,
    invoiceNumber: string
  ) {
    FinanceService.getInvoice(partnerCode, invoiceNumber);
  };

  /* INTRO START */

  $scope.tutorialSteps = [
    {
      element: ".js-Sidebar-content",
      title: "Filter",
      content: "TUTOR_SEARCH",
      position: "left"
    },
    {
      element: ".tableRow",
      title: "LABEL_RESULTS",
      content: "TUTOR_PANEL",
      position: "top"
    }
  ];

  /* INTRO END */

  $scope.addInvoice = function addInvoice(invoice: any) {
    invoice.checked = !invoice.checked;

    if (!invoice.checked) {
      if ($scope.multiActionSelected.length < 5) {
        invoice.checked = true;
        $scope.multiActionSelected.push(invoice.invoiceNumberField);
        $scope.multiActionCompanyCode.push(invoice.companyCodeField);
        $scope.numberOfSelected = $scope.multiActionSelected.length;
        if ($scope.numberOfSelected === 2) {
          $(".selectedBar")
            .css({ opacity: 0, display: "block" })
            .fadeTo(600, 1);
        }
      } else {
        SamskipNotify.displayError("ERROR_INVOICE_MAX_SELECTED");
      }
    } else {
      invoice.checked = false;
      const idxInvoiceNumber = $scope.multiActionSelected.indexOf(
        invoice.invoiceNumberField
      );
      $scope.multiActionSelected.splice(idxInvoiceNumber, 1);
      const idxCompanyCode = $scope.multiActionCompanyCode.indexOf(
        invoice.companyCodeField
      );
      $scope.multiActionCompanyCode.splice(idxCompanyCode, 1);
      $scope.numberOfSelected = $scope.multiActionSelected.length;
      if ($scope.numberOfSelected < 2) {
        $(".selectedBar").fadeTo(600, 0, () => {
          $(".selectedBar").css("display", "none");
        });
      }
    }
  };

  /**
   * Opens a specific SAP invoice
   * @param  {string} invoiceNr SAP invoice number
   */
  $scope.displayInvoice = function displayInvoice(
    invoiceNr: string,
    companycode: number
  ) {
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const sapID = selectedCompany.SapID;
    FinanceService.openInvoice(sapID, invoiceNr, companycode);
  };

  /**
   * Opens a specific SAP invoice
   * @param  {string} invoiceNr SAP invoice number
   */
  $scope.displayMultipleInvoices = function displayMultipleInvoices() {
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const sapID = selectedCompany.SapID;
    FinanceService.openMultipleInvoices(
      sapID,
      $scope.multiActionSelected,
      $scope.multiActionCompanyCode
    );
  };

  // Check if an invoice should be clickable
  // Invoices that are 'Safnreikningar' and are issued after 1st Januar 2015
  $scope.invoiceClickable = function invoiceClickable(invoice: any) {
    return (
      ["DZ", "D1"].indexOf(invoice.typeIdField) == -1 &&
      invoice.invoiceNumberField !== ""
    );
  };

  $scope.formatDate = function formatDate(date: string, dateFormat?: string) {
    return DateUtilities.formatDate(date, dateFormat);
  };

  // Returns the appropriate CSS class and translation for a transaction row
  // Depends whether the transaction is a deposit or if it's paid/unpaid
  $scope.getStatusTextClass = function getStatusTextClass(transaction: any) {
    const obj: any = {};

    if (transaction.depositField === "1") {
      obj.cssClass = "";
      obj.translation = "LABEL_DEPOSIT";
    } else if (transaction.balancedField === 0) {
      obj.cssClass = "red";
      obj.translation = "LABEL_UNPAID";
    } else if (transaction.balancedField === 1) {
      obj.cssClass = "green";
      obj.translation = "LABEL_PAID";
    }

    return obj;
  };

  $scope.samExcel = {};

  $scope.samExcel.excelButtonTitle = function excelButtonTitle() {
    return "Excel";
  };

  // Downloads finance transactions in Excel
  // API responds with a Excel file
  $scope.samExcel.downloadExcel = function downloadExcel(
    filteredFinances: any
  ) {
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;

    const sapID = selectedCompany.SapID;

    return FinanceService.allTransactionsInExcel(
      sapID,
      $scope.dateFrom,
      $scope.dateTo,
      filteredFinances
    );
  };

  $scope.samExcel.excelFileName = function excelFileName() {
    return `Transactions.${$scope.dateFrom}-${$scope.dateTo}`;
  };

  /**
   * Datepicker functionality
   */
  $scope.datePicker = {
    /**
     * Function to to call when a new data range is selected via the
     * samDatepicker directive
     * @param  {moment} startDate Start date of the updated date range
     * @param  {moment} endDate   End date of the updated date range
     */
    datesUpdated: function datesUpdated(
      startDate: moment.Moment,
      endDate: moment.Moment
    ) {
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");

      $scope.dateFrom = formattedStartDate;
      $scope.dateTo = formattedEndDate;
      saveDateRange(formattedStartDate, formattedEndDate);

      getFinanceList();
    }
  };

  function getDataRange() {
    const financeSettings = getWebSettingFinanceListPage();

    $scope.dateFrom = financeSettings.DateFrom;
    $scope.dateTo = financeSettings.DateTo;
  }

  function saveDateRange(dateFrom: string, dateTo: string) {
    const financeSettings = getWebSettingFinanceListPage();

    financeSettings.DateFrom = dateFrom;
    financeSettings.DateTo = dateTo;

    UserService.setWebSetting(
      USERWEBSETTINGS.FinanceListPage,
      JSON.stringify(financeSettings)
    );
  }

  function getWebSettingFinanceListPage() {
    // Make sure the settings object exists and doesn't return an empty string
    const financeSettingsValue = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.FinanceListPage
    );
    return financeSettingsValue ? JSON.parse(financeSettingsValue) : {};
  }

  // Get web settings for selected companies
  function getCompaniesSelectedObj() {
    const companiesSettings = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.SelectedCompanies
    );

    if (!companiesSettings) return [];

    let companiesSelectedObj;
    if (companiesSettings !== "") {
      companiesSelectedObj = JSON.parse(companiesSettings);
    } else {
      companiesSelectedObj = [];
    }
    return companiesSelectedObj;
  }

  function getSingleInvoiceInExcel(invoiceNumber: string) {
    const currentUser = UserService.getUserProfile();
    const selectedCompany = UserService.getSelectedCompany();
    if (!currentUser) return;
    const sapId = selectedCompany.SapID;
    FinanceService.getInvoiceExcel(sapId, invoiceNumber).then(
      (data: any) => {
        const out = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(out, `Invoice_${invoiceNumber}_details.xlsx`);
      },
      (data: any) => {
        const reader = new FileReader();
        const out = new Blob([data], {
          type: "text/plain"
        });
        reader.readAsText(out);
        reader.onloadend = function onloadend() {
          SamskipNotify.displayError(reader.result as string);
        };
      }
    );
  }
  $scope.getDropdownItems = function getDropdownItems(invoice: any) {
    return new Promise((resolve: Function, reject: Function) => {
      resolve([
        {
          label: "Excel",
          iconClass: "fa fa-download fa-fw",
          onClick: function onClick() {
            getSingleInvoiceInExcel(invoice.invoiceNumberField);
          }
        }
      ]);
    });
  };

  function searchFinance(query?: string): void {
    if (!currentUser) return;
    const selectedCompany = UserService.getSelectedCompany();
    if ($scope.inSearchAPICall) return;
    if (query === undefined || query.length < 1) {
      $state.go(MAIN_STATE);
      return;
    }

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderFinanceList").fadeTo(400, 1);
    $scope.domestics = [];

    $scope.inSearchAPICall = true;
    GeneralLoader.increase();
    FinanceService.searchTransactions(
      selectedCompany.SapID,
      query.toUpperCase()
    )
      .then(handleData)
      .then(() => {
        $scope.inSearchAPICall = false;
        UtilityFunctions.setListSizes(
          UserService.getLocalWebSettingValue(
            USERWEBSETTINGS.SelectedListViewSize
          )
        );
      })
      .finally(() => {
        GeneralLoader.decrease();
      });
  }

  function loadDateSettings(): void {
    if (currentStateParams.dateFrom && currentStateParams.dateTo) {
      $scope.dateFrom = currentStateParams.dateFrom;
      $scope.dateTo = currentStateParams.dateTo;
    } else {
      try {
        const listPageSetting = UserService.getLocalWebSettingValue(
          USERWEBSETTINGS.FinanceListPage
        );
        if (!listPageSetting) throw new Error("Failed loading settings");

        const domesticSettings: ListPageDateSettings = JSON.parse(
          listPageSetting
        );

        if (!domesticSettings.DateFrom || !domesticSettings.DateTo) {
          throw new Error("Failed loading settings");
        }

        const mDateFrom = moment(domesticSettings.DateFrom);
        const mDateTo = moment(domesticSettings.DateTo);

        if (mDateTo.diff(mDateFrom, "months", true) > 3) {
          $scope.dateFrom = mDateTo.subtract(3, "months").format("YYYY-MM-DD");
        } else {
          $scope.dateFrom = domesticSettings.DateFrom;
        }

        $scope.dateTo = domesticSettings.DateTo;
      } catch (e) {
        $scope.dateFrom = moment()
          .subtract(2, "weeks")
          .format("YYYY-MM-DD");
        $scope.dateTo = moment().format("YYYY-MM-DD");
      }
    }
  }

  $scope.toggleHandler = function toggleHandler(): void {
    UtilityFunctions.removeCatToggle();
    UtilityFunctions.catToggle();
  };

  /**
   * Data loader
   */
  function dataLoader(stateName: string, stateParams: object) {
    const stateFunctions = [
      {
        states: [
          "finance",
          "finance_unpaid",
          "finance_due",
          "finance_due30",
          "finance_due30-60",
          "finance_due60-90",
          "finance_due90+"
        ],
        loader: () => {
          getFinanceList();
        }
      },
      {
        states: ["finance_unpaid"],
        loader: () => {
          $scope.Filter.status.value = "unpaid";
          $scope.dateFrom = currentStateParams.dateFrom;
          $scope.dateTo = currentStateParams.dateTo;
          saveDateRange($scope.dateFrom, $scope.dateTo);
        }
      },
      {
        states: [
          "finance_due",
          "finance_due30",
          "finance_due30-60",
          "finance_due60-90",
          "finance_due90+"
        ],
        loader: () => {
          $scope.Filter.status.value = "due";
          $scope.dateFrom = currentStateParams.dateFrom;
          $scope.dateTo = currentStateParams.dateTo;
          saveDateRange($scope.dateFrom, $scope.dateTo);
        }
      },
      {
        states: ["finance_due30"],
        loader: () => {
          $scope.Filter.duePeriodSelection.value.push(1);
        }
      },
      {
        states: ["finance_due30-60"],
        loader: () => {
          $scope.Filter.duePeriodSelection.value.push(2);
        }
      },
      {
        states: ["finance_due60-90"],
        loader: () => {
          $scope.Filter.duePeriodSelection.value.push(3);
        }
      },
      {
        states: ["finance_due90+"],
        loader: () => {
          $scope.Filter.duePeriodSelection.value.push(4);
        }
      },
      {
        states: ["finance_search"],
        loader: () => {
          $scope.query = currentStateParams.query;
          searchFinance(currentStateParams.query);
        }
      }
    ];

    runDataLoader();

    function runDataLoader() {
      stateFunctions
        .filter(item => {
          if (item && item.states.indexOf(stateName) !== -1) return item;
        })
        .map(item => item.loader)
        .forEach(fn => {
          fn();
        });
    }
  }
}

financeController.$inject = ["$scope", "$transition$"];
export default financeController;
