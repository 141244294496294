import * as angular from "angular";
import * as moment from "moment";
import { $uiRouter } from "Bootstrap/angular";
import { UserService, FinanceService } from "Services";
import FinanceController from "Finance/FinanceController";
import * as financeTemplate from "Finance/finance.html";
import { USER_ROLES } from "Constants/UserRoles";
import { Object } from "es6-shim";
import { Transition, TargetState } from "@uirouter/core";
import { react2angular } from "react2angular";
import FinanceStatusCollapse from "./financeStatusCollapse";

declare var Promise: any;

export const MAIN_STATE: string = "finance";

/**
 * Get dates for total unpaid invoices for a specific SAP ID
 * @param sapId SAP ID
 * @param optionalDateTo Optional date to
 */
async function getTotalUnpaidDatePromise(
  $transition$: Transition,
  sapId: string,
  optionalDateTo?: string
): Promise<TargetState | boolean> {
  // Check if we have dates in the state's params
  // If we don't, we have to fetch the dates from API
  // and redirect the transition to the state again
  // with the dates in params.

  const toStateParams = $transition$.params();
  if (toStateParams.dateFrom && toStateParams.dateTo) return true;

  const currentUser = UserService.getUserProfile();
  const selectedCompany = UserService.getSelectedCompany();
  if (!currentUser) return false;

  const overdueTransactionsInfo: FinanceSAPTransactionsOverdueOverview = await FinanceService.getCustomerOverdueTransactionsInfo(
    selectedCompany.SapID
  );

  const dateFrom = moment(
    overdueTransactionsInfo.TotalUnpaidDateFrom,
    "YYYY-MM-DD"
  ).format("YYYY-MM-DD");
  const dateTo =
    optionalDateTo ||
    moment(overdueTransactionsInfo.TotalUnpaidDateTo, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
  const newDate = { dateFrom, dateTo };

  // Don't mutate state transition params object, just create a new one
  // which overrides old one.
  const newStateParams = Object.assign({}, toStateParams, newDate);

  // Return the new target transition
  return $uiRouter.stateService.target($transition$.to(), newStateParams);
}

getTotalUnpaidDatePromise.$inject = ["$transition$", "sapId", "optionalDateTo"];

class Finance {
  constructor() {
    const dateToday = moment().format("YYYY-MM-DD");
    const date30Days = moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    const date60Days = moment()
      .subtract(60, "days")
      .format("YYYY-MM-DD");
    const date90Days = moment()
      .subtract(90, "days")
      .format("YYYY-MM-DD");

    const routes: any[] = [
      {
        name: MAIN_STATE,
        url: `/${MAIN_STATE}/transactions`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD"
        },
        data: { roles: [USER_ROLES.FINANCE] }
      },
      {
        name: `${MAIN_STATE}_unpaid`,
        url: `/${MAIN_STATE}/unpaid`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD"
        },
        data: { roles: [USER_ROLES.FINANCE] },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            const currentUser = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();

            if (currentUser && selectedCompany) {
              const sapId = selectedCompany.SapID;
              return getTotalUnpaidDatePromise($transition$, sapId);
            }
          }
        ]
      },
      {
        name: `${MAIN_STATE}_due`,
        url: `/${MAIN_STATE}/due`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD"
        },
        data: { roles: [USER_ROLES.FINANCE] },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            const currentUser = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();

            if (currentUser && selectedCompany) {
              const sapId = selectedCompany.SapID;
              return getTotalUnpaidDatePromise($transition$, sapId);
            }
          }
        ]
      },
      {
        name: `${MAIN_STATE}_due30`,
        url: `/${MAIN_STATE}/due30`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.FINANCE] },
        params: {
          dateFrom: date30Days,
          dateTo: dateToday,
          resultText: "LABEL_PERIOD"
        }
      },
      {
        name: `${MAIN_STATE}_due30-60`,
        url: `/${MAIN_STATE}/due30-60`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.FINANCE] },
        params: {
          dateFrom: date60Days,
          dateTo: date30Days,
          resultText: "LABEL_PERIOD"
        }
      },
      {
        name: `${MAIN_STATE}_due60-90`,
        url: `/${MAIN_STATE}/due60-90`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.FINANCE] },
        params: {
          dateFrom: date90Days,
          dateTo: date60Days,
          resultText: "LABEL_PERIOD"
        }
      },
      {
        name: `${MAIN_STATE}_due90+`,
        url: `/${MAIN_STATE}/due90+`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD"
        },
        data: { roles: [USER_ROLES.FINANCE] },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            const currentUser = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();

            if (currentUser && selectedCompany) {
              const sapId = selectedCompany.SapID;
              return getTotalUnpaidDatePromise($transition$, sapId);
            }
          }
        ]
      },
      {
        name: `${MAIN_STATE}_search`,
        url: `/${MAIN_STATE}/search/:query`,
        parent: "masterPage",
        template: `${financeTemplate}`,
        controller: FinanceController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_SEARCH"
        },
        data: { roles: [USER_ROLES.SHIP] }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular
  .module("serviceWebApp")
  .run(Finance)
  .component(
    "financeStatusCollapse",
    react2angular(FinanceStatusCollapse, ["sapId", "dateFrom", "dateTo"])
  );
