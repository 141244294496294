import React, { useState, useEffect } from "react";
import { Collapse } from "Component";
import {
  Card,
  Divider,
  Grid,
  GridRow as Row,
  GridColumn as Column
} from "semantic-ui-react";
import { FinanceService, TranslationService } from "Services";
import styles from "./index.less";
import { UtilityFunctions } from "Utilities";
import { reaction } from "mobx";

interface FinanceStatusCollapseProps {
  sapId: string;
  dateFrom: Date;
  dateTo: Date;
}

const FinanceStatusCollapse = (props: FinanceStatusCollapseProps) => {
  const [companies, setCompanies] = useState(
    [] as FinanceSAPCustomerPeriodBalance[]
  );
  const [lang, setLang] = useState(TranslationService.getSelectedLanguage());
  const { sapId, dateFrom, dateTo } = props;

  useEffect(() => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => setLang(TranslationService.getSelectedLanguage())
    );
    setCompanies([]);
    FinanceService.getCustomerStatusOverPeriod(sapId, dateFrom, dateTo).then(
      data => {
        setCompanies(data);
      }
    );
  }, [sapId, dateFrom, dateTo]);

  const renderCompanyInfo = (company: FinanceSAPCustomerPeriodBalance) => {
    const renderRow = (
      description: string,
      amount: number,
      currency: string
    ) => {
      return (
        <Row>
          <Column width={8}>{description}</Column>
          <Column width={6}>
            {UtilityFunctions.thousandFNumber(Math.floor(amount))}
          </Column>
          <Column width={2}>{currency}</Column>
        </Row>
      );
    };

    const renderDate = (date: Date) => {
      // Add 1 to month as it is zero based
      const toLeadingZeroString = (value: number) => {
        return value <= 9 ? `0${value}` : value;
      };

      return `${toLeadingZeroString(date.getDate())}.${toLeadingZeroString(
        date.getMonth() + 1
      )} '${date
        .getFullYear()
        .toString()
        .substr(2)}`;
    };

    return (
      <Card key={company.CurrentStatus.CompanyCode} className={styles.card}>
        <Card.Content>
          <Card.Header>
            {TranslationService.translate(
              `LABEL_COMPANY_${company.CurrentStatus.CompanyCode}`
            )}
          </Card.Header>
          <Divider />
          <Card.Description>
            <Grid className={styles.grid}>
              {renderRow(
                TranslationService.translate("LABEL_CUSTOMER_TOTALBALANCE"),
                company.CurrentStatus.TotalBalance,
                company.CurrentStatus.Currency
              )}
              <Divider />
              {renderRow(
                TranslationService.translate("LABEL_CUSTOMER_MAXWITHDRAWAL"),
                company.CurrentStatus.MaximumWithdrawal,
                company.CurrentStatus.Currency
              )}
            </Grid>
            {company.KeyBalanceFrom && company.KeyBalanceTo && (
              <>
                <Divider />
                <h4 className="sub-header">
                  {TranslationService.translate("TEXT_BALANCE_OVER_PERIOD")}
                </h4>
                <Divider />
                <Grid className={styles.grid}>
                  {renderRow(
                    `${TranslationService.translate(
                      "LABEL_START"
                    )} (${renderDate(new Date(company.KeyBalanceFrom.Date))})`,
                    company.KeyBalanceFrom.Balance,
                    company.KeyBalanceFrom.Currency
                  )}
                  <Divider />
                  {renderRow(
                    `${TranslationService.translate("LABEL_END")} (${renderDate(
                      new Date(company.KeyBalanceTo.Date)
                    )})`,
                    company.KeyBalanceTo.Balance,
                    company.KeyBalanceTo.Currency
                  )}
                </Grid>
              </>
            )}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  };

  return (
    <Collapse
      title={TranslationService.translate("LABEL_FINANCESTATUS")}
      open={true}
      className={`${styles.collapse} u-backgroundWhite`}
    >
      <div className={"u-flex u-flexAlignItemsBaseline u-flexJustifyAround"}>
        {companies.length > 0 && companies.map(item => renderCompanyInfo(item))}
      </div>
    </Collapse>
  );
};

export default FinanceStatusCollapse;
